import React from "react";

import Venderimg from "assets/technology/venderimg.svg";

const VendertPortal = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 bg-[#F5F9FF] mt-[40px] rounded-lg">
      <div className="grid lg:grid-cols-2 gap-24 p-[40px] md:p-[60px]">
        <div className="order-2 lg:order-1">
          <img src={Venderimg} alt=" client portal image" />{" "}
        </div>
        <div className="flex items-center justify-center order-1 lg:order-2">
          <div>
            <p className="text-[#00173A] text-3xl md:text-[40px] font-primary font-semibold leading-[50px]">
              Vendor Portal
            </p>
            <p className="text-[#00173A] mt-8 font-opensans leading-8 font-normal text-lg">
              The Tomedes Vendor Portal enhances your experience with automated
              invoicing, real-time status updates, and easy document management.
              It ensures efficient payment processes and secure, streamlined
              communication, making your partnership with us more productive and
              hassle-free.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendertPortal;
