import React from "react";
import Clientimg from "assets/technology/clientimg.svg";
import Venderimg from "assets/technology/venderimg.svg";

const ClientPortal = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 bg-[#F5F9FF] mt-8 rounded-lg ">
      <div className="grid lg:grid-cols-2 gap-24 p-[40px] md:p-[60px]">
        <div className="flex items-center justify-center">
          <div>
            <p className="text-[#00173A] text-3xl md:text-[40px] font-primary font-semibold leading-[50px]">
              Client Portal
            </p>
            <p className="text-[#00173A] mt-8 font-opensans leading-8 font-normal text-lg">
              Streamline your translation management with the Tomedes Client
              Portal. Easily monitor order and payment history, download
              invoices, view dedicated customer support managers, access
              translation certificates, and enjoy customizable features for a
              more efficient experience.
            </p>
          </div>
        </div>
        <div className="">
          <img src={Clientimg} alt=" client portal image" />{" "}
        </div>
      </div>
    </div>
  );
};
export default ClientPortal;
