import React from "react";
import Layout from "components/layout";
import TranslationTechnology from "components/translationTechnology";
import SEO from "components/seo";

export default function index() {
  const description =
    "Technology tools of Tomedes translation services - Our translators utilize a wide range of programs and technologies to tailor their work to the client's needs.";
  const title = "Translation Tools - Technology Tools | Tomedes";
  const keywords =
    "Translation technology, Professional translation, Translation Services, Urgent translation, Translation company.";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/translation-technology"
      />
      <TranslationTechnology />
    </Layout>
  );
}
