import FirstCardImage from "assets/images/memoq.svg";
import SecondCardImage from "assets/technology/sdl.svg";
import ThirdCardImage from "assets/technology/madcap.svg";
import FourtCardImage from "assets/technology/poedit.svg";
import FifthCardImage from "assets/technology/smartling.svg";
import SixthCardImage from "assets/technology/crowdin.svg";
export const TechnologyData = [
  {
    imgUrl: FirstCardImage,
    title: "MemoQ – Translation",
    description:
      "MemoQ is a common CAT tool used by many professional translators, language service providers, and localization companies worldwide. MemoQ earned its reputation as an industry favorite thanks to a streamlined workflow designed to expedite the translation process.",
  },
  {
    imgUrl: SecondCardImage,
    title: "SDL Trados – Translation",
    description:
      "SDL Trados is a leading translation tool that provides a comprehensive suite of utilities for independent translators, translation agencies, corporate translation departments, and academic bodies. SDL Trados guides the user through all stages of the translation process, from big-picture project administration and glossary management to proofreading, editing, and building a Translation Memory.",
  },
  {
    imgUrl: ThirdCardImage,
    title: "MadCap – Translation",
    description:
      "MadCap Flare is a professional content editing tool that export to a wide range of file types and frameworks, such as .docx, HTML5, .pdf, .NET, and others",
  },
  {
    imgUrl: FourtCardImage,
    title: "Poedit – Translation & Localization",
    description:
      "A widely popular tool for translating .po files, used for localizing gettext-based apps and websites.",
  },
  {
    imgUrl: FifthCardImage,
    title: "Smartling – Translation & Localization",
    description:
      "Smartling is a cloud platform for managing complex localization projects. Smartling enables users to translate websites, apps, and information systems quickly and efficiently.",
  },
  {
    imgUrl: SixthCardImage,
    title: "Crowdin – Translation & Localization",
    description:
      "Crowdin is a translation and localization management platform for mobile apps, websites, software, and information systems.",
  },
];
